<template>
    <div class="module">
        <van-nav-bar title="交通控制" left-arrow  @click-left="$router.go(-1)" />
        <van-steps direction="vertical" :active="19" active-color="#1677ff" class="setps-content">
            <van-step v-for="(item,index) in devList" :key="index">
                <div class="title">
                    {{item.name}}
                </div>
                <div class="status">
                    运行状态：
                    <van-tag size="medium" type="primary" v-if="item.status">已开启</van-tag>
                    <van-tag size="medium" type="warning" v-else>已关闭</van-tag>
                </div>
                <div v-if="item.type===1">
                    <img src="../../../assets/tunnel/img/logo/green.png" width="38%" height="100%"/>
                </div>
                <div v-else-if="item.type===2" class="hint-font"><div class="hint-0">前方隧道禁止停车</div></div>
                <div v-else-if="item.type===3" class="hint-font">
                    <div>正面通行</div>
                    <img class="img" src="../../../assets/tunnel/img/logo/arrows.png" width="15%" height="100%"/>
                </div>
                <div v-else-if="item.type===4" class="marbtm5">故障报警：
                    <van-tag size="medium" type="success" v-if="item.alart === 1">已开启</van-tag>
                    <van-tag size="medium" type="danger" v-else>已关闭</van-tag>
                </div>
                <div v-else-if="item.type===5">
                        <div class="marbtm5">过车总数：{{item.sum}} 辆</div>
                        <div class="marbtm5">平均速度：{{item.speed}} km/h</div>
                        <div class="marbtm5">两轮车：{{item.car0}}</div>
                        <div class="marbtm5">小型汽车：{{item.car1}}</div>
                        <div class="marbtm5">中型汽车：{{item.car2}}</div>
                        <div class="marbtm5">大型汽车：{{item.car3}}</div>
                        <div class="marbtm5">特大型汽车：{{item.car4}}(车道交通量 / 车道平均车速)</div>
                </div>
                <div>更新时间：{{item.time}}</div>
            </van-step>
        </van-steps>
    </div>
</template>

<script>
export default {
    data(){
        return{
            devList:[
                {name:'入京入口信号灯',type:1,status:1,time:'2021-11-01 12:30'},
                {name:'信息显示屏-进京',type:2,status:1,time:'2021-11-01 08:30'},
                {name:'进京1组左',type:3,status:1,time:'2021-11-01 11:30'},
                {name:'进京1组右',type:3,status:1,time:'2021-11-01 09:30'},
                {name:'进京2组左',type:3,status:1,time:'2021-11-01 12:30'},
                {name:'进京2组右',type:3,status:1,time:'2021-11-01 02:20'},
                {name:'进京3组左',type:3,status:1,time:'2021-11-01 12:30'},
                {name:'进京3组右',type:3,status:1,time:'2021-11-02 12:00'},
                {name:'进京4组左',type:3,status:1,time:'2021-11-02 12:10'},
                {name:'进京4组右',type:3,status:1,time:'2021-11-02 14:30'},
                {name:'车行横洞门4',type:4,status:1,alart:1,time:'2021-11-02 16:00'},
                {name:'出京入口信号灯',type:1,status:1,time:'2021-11-02 18:10'},
                {name:'信息显示屏-出京',type:2,status:1,time:'2021-11-02 19:00'},
                {name:'出京1组左',type:3,status:1,time:'2021-11-02 19:10'},
                {name:'出京1组右',type:3,status:1,time:'2021-11-02 19:40'},
                {name:'出京2组左',type:3,status:1,time:'2021-11-02 19:10'},
                {name:'出京2组右',type:3,status:1,time:'2021-11-02 19:40'},
                {name:'车行横洞门1',type:4,status:1,alart:1,time:'2021-11-02 19:40'},
                {name:'车载检测器-出京1',type:5,status:1,sum:18,speed:35.5,car0:'0/0',car1:'17/46',car2:'0/0',car3:'0/0',car4:'1/25',time:'2021-11-02 19:40'},
                {name:'车载检测器-出京2',type:5,status:1,sum:11,speed:51.2,car0:'0/0',car1:'11/51',car2:'0/0',car3:'0/0',car4:'1/25',time:'2021-11-02 19:40'},
                {name:'车行横洞门2',type:4,status:0,alart:0,time:'2021-11-02 19:40'},
            ]
        }
    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>